.Formulaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1vh;
  padding-bottom: 6vh;
  background-color: var(--background-color);
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7vh;
}

/*Style de conteneur qui contien deux blocs (formulaire et cadre d'informations)*/
.Formulaire__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Formulaire__container_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  padding-right: 3.5vw;
}

/*Style de titre (Nous Contactez)*/
.Formulaire_titre {
  display: flex;
  color: var(--text-color);
  text-align: center;
  font-size: 3vw;
  font-family: "Product Sans", sans-serif;
  font-weight: 700;
  line-height: 3.7vh;
  padding-left: 3vw;
  position: relative;
}

/*Style de la ligne au dessous de titre*/
.Formulaire_titre:after {
  content: "";
  position: absolute;
  bottom: -3.3vh;
  width: 22.5vw;
  height: 1.3vh;
  background-color: #f801eb;
  border-radius: 4vh;
}

/**********Style de formulaire**********/
.Formulaire__block form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*Style des inputs(text,email,tel,objet)*/
.Formulaire__inputs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Product Sans", sans-serif;
  padding-top: 1vh;
}

.Formulaire__inputs-container input[type="text"],
.Formulaire__inputs-container input[type="email"],
.Formulaire__inputs-container input[type="tel"],
.Formulaire__inputs-container input[name="objet"] {
  width: 16.8vw;
  margin: 0.8vh;
  padding: 2vh;
  font-size: 2vh;
  border-radius: 1.5vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
  color: var(--border-color);
}

/*Style d'input (message)*/
.Formulaire__inputs-container textarea[name="message"] {
  width: 37.5vw;
  height: 22vh;
  margin: 1.5vh;
  font-family: "Product Sans", sans-serif;
  padding-top: 2vh;
  padding-left: 2vh;
  font-size: 2vh;
  border-radius: 2vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
  color: var(--border-color);
}

/*Couleur de placeholder selon le mode */
.Formulaire__inputs-container input::placeholder,
.Formulaire__inputs-container textarea::placeholder {
  color: var(--placeholder-color);
}

/* Style des boutons */
.Formulaire__container {
  display: flex;
  justify-content: center;
  gap: 1vw;
  padding-top: 2vh;
}

/*Style de bouton (Réinisialiser)*/
.Formulaire__reset-button-forum {
  border: none;
  background-color: transparent;
  color: var(--link-color);
  font-size: 1.8vh;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 11vw;
  cursor: pointer;
  padding: 2vh 1.5vw;
}

/*Style de bouton (Réinisialiser) au clic*/
.Formulaire__reset-button-forum:hover {
  font-size: 1vw;
}

/*Style de bouton (Envoyer)*/
.Formulaire__buttons-container_forum button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11vw;
  height: 5.3vh;
  border-radius: 4vh;
  background: var(--Formulaire__buttons-container);
  color: #fff;
  font-size: 1.8vh;
  font-family: "Poppins", sans-serif;
  border: 0.3vh solid #9905be;
  position: relative;
  left: 11vw;
  cursor: pointer;
  padding: 2vh 0.5vh;
}

.Formulaire__buttons-container_forum button:hover {
  background: linear-gradient(to right, #f801eb, #350b8d);
  color: #fff;
  animation: gradientAnimation 0.4s linear forwards;
  border-color: #f801eb, #350b8d;
}

/*******Style de deuxiéme bloc (cadre d'informations)**********/
.cadre {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--cadre);
  border: 0.30vh solid white;
  border-radius: 2.2vh;
}

.Information__cadre {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 28vw;
  height: 41vh;
  padding-left: 1.2vw;
  padding-right: 1.2vw;
  padding-top: 2.4vh;
  border: 0.30vh solid transparent;
  border-radius: 2.2vh;
  transition: 1s ease;
}

/*Style de titre dans le cadre */
.Information__cadre_titre {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-size: 4.2vh;
  font-family: "Product Sans", sans-serif;
  position: relative;
}

/*Style de ligne sous le titre*/
.Information__cadre_titre:after {
  content: "";
  position: absolute;
  width: 16.5vw;
  height: 1.2vh;
  background-color: #f801eb;
  border-radius: 4vh;
  bottom: -12px;
}

/*Style de contenu des informations */
.Information__cadre_Information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 28vw;
  color: #FFF;
  font-size: 3.2vh;
  font-family: "Product Sans", sans-serif;
  line-height: 4vh;
  /*espacement entre les lignes de contenu*/
  text-align: center;
  padding-top: 2.3vh;
  /*alignement de contenu par rapport au titre*/
}

/*Style de chaque span*/
.Information__cadre_Information>span {
  padding-bottom: 1.6vh;
  /* espacement entre les parties du contenu*/
}

/*Style de deuxième cadre au clic*/
.Information__cadre:hover {
  background-color: #350B8D;
  border-radius: 2.2vh;
  cursor: pointer;
}

/*********************************** Responsive *************************************/
/* Media Query for small screens */
@media only screen and (max-width: 768px) {
  .Formulaire_titre {
    display: none;
  }

  .Formulaire_titre:after {
    display: none;
  }

  .Formulaire__inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Formulaire__container {
    align-items: center;
    padding-top: 2vh;
    padding-right: 0;
    flex-direction: column;
  }

  .Formulaire__inputs-container input[type="text"],
  .Formulaire__inputs-container input[type="email"],
  .Formulaire__inputs-container input[type="tel"],
  .Formulaire__inputs-container input[name="objet"] {
    width: 69.3vw;
    height: 4vh;
    padding: 1vh;
    font-size: 2vh;
    border-radius: 2vh;
    background-color: var(--background-color);
    border: 0.30vh solid var(--border-color);
    color: var(--border-color);
    padding-bottom: 1.5vh;
  }

  .Formulaire__inputs-container textarea[name="message"] {
    width: 69.3vw;
    height: 12vh;
    padding: 1vh;
    font-size: 2vh;
    border-radius: 2vh;
    background-color: var(--background-color);
    border: 0.30vh solid var(--border-color);
    color: var(--border-color);
    padding-bottom: 1.5vh;
  }

  .Formulaire__container_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    padding-bottom: 10vh;

  }

  .Formulaire__buttons-container_forum button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33vw;
    border-radius: 4vh;
    background: var(--btn_responsive);
    color: #fff;
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be;
    position: relative;
    left: 6vw;
    cursor: pointer;
    padding: 2vh 0.5vh;
  }

  .Formulaire__buttons-container_forum button:hover {
    background: linear-gradient(to right, #f801eb, #350b8d);
    color: #fff;
    animation: gradientAnimation 0.4s linear forwards;
    border-color: #f801eb, #350b8d;
  }

  /*Style de bouton (Réinisialiser)*/
  .Formulaire__reset-button-forum {
    border: none;
    background-color: transparent;
    color: var(--link-color);
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    position: relative;
    left: 1vw;
    cursor: pointer;
    padding: 2vh 1.5vw;
  }

  .Formulaire__reset-button-forum:hover {
    display: flex;
    font-size: 2vh;
  }
.cadre{
  display: none;
}
  .Information__cadre {
    display: none;
  }
}