@media only screen and (max-width: 768px) {
  .service_small {
    background-image: url('../../../assets/images/servicee.png');
    background-size: cover;
    background-position: center;
  }

  .Accueil_service_SmallScreen {
    display: flex;
    flex-direction: column;
    margin-top: 37vh;
  }

  .title_smallScreen {
    display: flex;
    justify-content: center;
    color: white;
    font-family: "Product Sans", sans-serif;
    font-weight: 700;
    font-size: 5vw;
    padding-top: 7vh;
    position: relative;
  }

  .title_smallScreen:after {
    content: "";
    position: absolute;
    width: 31vw;
    height: 0.5vh;
    background-color: #f801eb;
    border-radius: 8vw;
    bottom: -0.1vh;
  }

  .CarouselContainer_AccueilService {
    display: flex;
    overflow-x: auto;
    padding-top: 2vh;
    
    padding-left: 23vw;
    padding-right: 24vw;
    gap: 27vw;
    background-size: cover;
    background-position: center;
    /*N'afficher pas le scroll bar */
    &::-webkit-scrollbar {
      display: flex;
    }
  }

  .CarouselItem_AccueilService {
    flex-shrink: 0;
    padding: 1rem;
    border-radius: 9vw;
    border: 0.35vh solid white;
    width: 50vw;
    height: 65vh;
  }
/*style de 'blinking dots'*/
  .bloc_with_dots{
    display: flex;
  }
  .loader {
    display: flex;
    position: absolute;
    padding-top: 49vw;
    padding-left: 46vw;
  }
  @keyframes blink {
    80% {
      color: white;
    }
  }
  .loader__dot {
    animation: 2s blink infinite;
    font-size: 15vw; /* Change the font size to your desired size */
  }
  .loader__dot:nth-child(2) {
    animation-delay: 250ms;
  }
  .loader__dot:nth-child(3) {
    animation-delay: 500ms;
  }
  


  .ServiceSmallScreen__cadre_image {
    display: flex;
    width: 15vw;
    padding-left: 18vw;
    padding-top: 1vh;
    padding-top: 1.5vh;
  }

  .CarouselTitle_AccueilService {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 2.8vw;
    font-family: "Product Sans", sans-serif;
    padding-bottom: 1vh;
  }

  .CarouselContent_AccueilService {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #FFF;
    font-size: 2.5vw;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    padding-bottom: 1vh;
  }

  .CarouselContent3_AccueilService {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #FFF;
    font-size: 2.5vw;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    padding-bottom: 2vh;
  }
/*style de lien read more*/
  a:-webkit-any-link {
    display: flex;
    color: #fff;
    justify-content: center;
    font-size: 2.5vw;
}


  /**********/
  .CarouselTitle2_AccueilService {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 2.8vw;
    font-family: "Product Sans", sans-serif;
    padding-top: 1vh;
    padding-bottom: 1.5vh;
  }

  .CarouselTitle3_AccueilService {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 3.2vw;
    font-family: "Product Sans", sans-serif;
    padding-top: 1vh;
    padding-bottom: 2.2vh;
  }

  /*****/
  .ServiceSmallScreen__cadre_image3 {
    display: flex;
    width: 18vw;
    padding-left: 16vw;
    padding-right: 17vw;
    padding-bottom: 1vh;
    padding-top: 1vh;
  }

  .ServiceSmallScreen__cadre_image4 {
    display: flex;
    width: 16vw;
    padding: 1vh 17vw;
  }

  .btn_projet_smallscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27vw;
    height: 6.5vh;
    border-radius: 4vh;
    background: linear-gradient(to right, #f801eb, #350b8d);
    color: #fff;
    font-size: 2vw;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be;
    cursor: pointer;
    margin-bottom: 5vh;
  }

  .responsive_btn {
    display: flex;
    justify-content: center;
    padding-top: 7vh;
  }

  @media screen and (max-width: 600px) {
    .title_smallScreen {
      font-size: 6.5vw;
    }

    .title_smallScreen:after {
      width: 42vw;
      height: 0.35vh;
    }

    .CarouselContainer_AccueilService {
      padding-left: 16vw;
      padding-right: 16vw;
    }

    .CarouselItem_AccueilService {
      width: 58vw;
      height: 60vw;
    }

    .ServiceSmallScreen__cadre_image {
      padding-left: 21vw;
    }

    .CarouselTitle_AccueilService {
      font-size: 3.2vw;
    }

    .CarouselContent_AccueilService {
      font-size: 2.9vw;
    }

    a:-webkit-any-link {
      font-size: 4vw;
  }

    .ServiceSmallScreen__cadre_image3 {
      width: 22vw;
      padding-left: 17vw;
    }

    .CarouselTitle2_AccueilService {
      font-size: 3.2vw;
    }

    .CarouselContent3_AccueilService {
      font-size: 2.9vw;
    }

    .ServiceSmallScreen__cadre_image4 {
      display: flex;
      width: 20vw;
      padding: 1vh 19vw;
    }

    .btn_projet_smallscreen {
      width: 44vw;
      height: 5.5vh;
      font-size: 3vw;
    }

    .Accueil_service_SmallScreen {
      display: flex;
      flex-direction: column;
      margin-top: 7vh;
    }
    .loader {
      display: flex;
      position: absolute;
      padding-top: 63vw;
      padding-left: 45vw;
    }
  }
}