@media only screen and (max-width: 768px) {
  .Service_SmallScreen {
    background: var(--background-color);
    color: var(--text-color);
  }

  .left-side-Service {
    float: left;
    height: 14vh;
    padding-top: 2vh;
    padding-right: 4vh;
  }

  .right-side {
    float: right;
    width: 50%;
  }

  .Contenu {
    width: 87%;
    margin: 0 auto;
    padding-bottom: 4vh;
    font-size: 2vh;
  }

  .titre_smallscreen {
    color: #F801EB;
    padding-top: 2vh;
    font-size: 3vh;
  }

  .service_p_SmallScreen {
    line-height: 4vh;
    text-align: justify;
  }

  .SmallScreen {
    background: var(--background-color);
  }
  .responsive_bouton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
    height: 5.3vh;
    border-radius: 4vh;
    background: linear-gradient(to right, #f801eb, #350b8d);
    color: #fff;
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be;
    cursor: pointer;
  }
}