@media only screen and (max-width: 768px) {
  .left-side {
    float: left;
    width: 44%;
    padding-top: 3vh;
    padding-right: 2vw;
  }
  .Apropos_SmallScreen{
    background: var(--background-color);
    color: var(--text-color);
  }
  .right-side {
    float: right;
    width: 60%;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5vh;
    
  }
  .p_SmallScreen{
    line-height: 4vh;
    text-align: justify;
    font-size: 2vh;
    font-weight: 600;
  }
  .custom-span {
    display: block; 
    padding-bottom: 3vh;
    color: var(--text-color);
  }
  }
 
