.Service_S {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  padding-bottom: 10vh;
}

.Service_S__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vh;
}

.Service_S__block {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: -2vh;
}

/*Style de titre*/
.Service_S_titre {
  display: flex;
  width: 35vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 4vh;
  font-weight: 700;
  line-height: 4vh;
  height: 6vh;
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif;
  padding-bottom: 2vh;
  padding-right: 1vw;
}

/*Style de paragraphe*/
.Service_S_paragraphe_p {
  display: flex;
  width: 40vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 3vh;
  line-height: 3.5vh;
  padding-left: 4vw;
}

.Service_S_img {
  height: 60vh;
  padding-top: 4vh;
}

.scroll__container {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;

}

.scroll__button button {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  outline: none;
  padding-bottom: 1vh;
}

.scroll__button-image {
  animation: scrollAnimation 0.9s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(12px);
  }
}

.Service_S_container {
  scroll-behavior: smooth;
}

/******************************Responsive******************************************/
/* Media query pour écrans de petite taille */
@media only screen and (max-width: 768px) {
  .Service_S {
    display: flex;
    flex-direction: column;
    padding-bottom: 40vh;
  }

  .Service_S_titre {
    display: flex;
    flex-direction: column;
    width: 77vw;
    font-size: 3.5vh;
    color: var(--text-color);
    font-family: "Product Sans", sans-serif;
    font-weight: 700;
    padding-bottom: 7vh;
    text-align: left;
    padding-top: 12vh;
    padding-right: 5vw;

  }

  .Service_S_paragraphe_p {
    display: flex;
    width: 88vw;
    flex-direction: column;
    color: var(--text-color);
    font-size: 2.2vh;
    font-family: "Poppins", sans-serif;
    padding-top: 2vh;
    text-align: left;
    padding-left: 6vw;
  }

  .Service_S_img {
    display: none;
  }

  .scroll__button {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .Service_S {
      display: flex;
      flex-direction: column;
    /*   background-image: linear-gradient(to top, rgba(29, 28, 28, 0.745), rgba(0, 0, 0, 0.7)),
        url('../../../assets/images/Mask group (2).png'); */
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      
    }

    .Service_S_titre {
      color: var(--text-color);    }

    .Service_S_paragraphe_p {
      color: var(--text-color);
        }
  }
}