@media only screen and (max-width: 768px) {
  .CarouselContainer_SmallScreen {
    display: flex;
    overflow-x: auto;
    padding: 4vh 12.5vw;
    gap: 25vw;
    background: var(--background-color);
    scrollbar-width: none;
    -ms-overflow-style: none;

    /*N'afficher pas le scroll bar */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .CarouselItem_SmallScreen {
    flex-shrink: 0;
    padding: 1rem;
    background: var(--cadre);
    border-radius: 3vh;
    border: 0.35vh solid white;
  }

  .CarouselItem_SmallScreen:hover {
    background-color: #350B8D;
    border-color: transparent;
    cursor: pointer;
  }

  .CarouselTitle_SmallScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 2.5vh;
    font-family: "Product Sans", sans-serif;
    position: relative;
  }

  .CarouselTitle_SmallScreen:after {
    content: "";
    position: absolute;
    width: 134px;
    height: 0.35vh;
    background-color: #f801eb;
    border-radius: 8vw;
    bottom: -0.1vh;
    /*L'emplacement de l'alignement par rapport au titre*/
  }

  .CarouselContent_SmallScreen {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 65vw;
    color: #FFF;
    font-size: 2.1vh;
    font-family: "Poppins", sans-serif;
    line-height: 2.7vh;
    /*espacement entre les lignes de contenu*/
  }

  .CarouselContent_SmallScreen>span {
    padding-bottom: 1.2vh;
  }

  /**********/
  .CarouselItem2_SmallScreen {
    flex-shrink: 0;
    background: var(--cadre);
    border-radius: 3vh;
    border: 0.35vh solid white;
    width: 64vw;
    padding: 1rem;
  }

  .CarouselItem2_SmallScreen:hover {
    background-color: #350B8D;
    border-color: transparent;
    cursor: pointer;
  }

  .CarouselTitle2_SmallScreen {
    display: flex;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 2.5vh;
    font-family: "Product Sans", sans-serif;
    line-height: 3.5vh;
  }

  .CarouselImage2_SmallScreen {
    display: flex;
    font-size: 1rem;
    border-radius: 50%;
    padding-top: 1.5vh;
    padding-left: 26.5vw;
  }

  .Apropos_loader {
    display: flex;
    position: absolute;
    padding-top: 25vh;
    padding-left: 33vw;
  }

  @keyframes blink {
    80% {
      color: white;
    }
  }

  .Apropos_loader__dot {
    animation: 2s blink infinite;
    font-size: 15vw;
    /* Change the font size to your desired size */
  }

  .Apropos_loader__dot:nth-child(2) {
    animation-delay: 250ms;
  }

  .Apropos_loader__dot:nth-child(3) {
    animation-delay: 500ms;
  }
}