.Partenaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 10vh;
  background-color: var(--background-color);

}

/* Style de titre */
.Partenaire_titre {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: "Product Sans", sans-serif;
  font-size: 50px;
  width: 30vw;
  text-align: center;
  color: var(--text-color);
}

.Partenaire_titre span {
  background: var(--Partenaire-titre-span);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Partenaire__container {
  width: 100%;
  background-color: var(--Partenaire-container-color);
  height: 30vh;
}

.partenaire_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.7vh;
}

.partenaire_image1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 13vh;
}

.partenaire_image3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8vh;
}

.partenaire_image4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
}

.slick-slide img {
  display: flex;
  width: 45%;
  height: 45%;
  padding-left: 8vw;
  transition: transform 0.3s ease-in-out;
}

.slick-slide img:hover {
  transform: scale(1.05);
}

/****************Responsive******************/
@media screen and (max-width: 768px) {
  .Partenaire_titre {
    font-size: 5vw;
    width: 50vw;
  }

  .Partenaire__container {
    width: 100%;
    height: 25vh;
  }

  .slick-slide img {
    display: flex;
    width: 50%;
    height: auto;
  }

  .partenaire_image1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11vh;
  }

  .partenaire_image3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8vh;
  }

  .partenaire_image4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 9vh;
  }

  .partenaire_image {
    padding-top: 2.5vh;
  }

  .partenaire_image2 {
    padding-top: 2.8vh;
  }

  @media screen and (max-width: 600px) {
    .Partenaire_titre {
      font-size: 6vw;
      width: 60vw;
    }

    .Partenaire__container {
      width: 100%;
      height: 15vh;
    }

    .partenaire_image1 {
      padding-top: 6vh;
    }

    .partenaire_image3 {
      padding-top: 4.5vh;
    }

    .partenaire_image4 {
      padding-top: 5.5vh;
    }
  }
}