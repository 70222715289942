.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  padding-bottom: 10vh;
}

.Contact__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vh;
}

.Contact__block {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: -2vh;
}

/*Style de titre*/
.Contact_titre {
  display: flex;
  width: 35vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 4vh;
  font-weight: 700;
  line-height: 4vh;
  height: 6vh;
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif;
  padding-bottom: 2vh;
  padding-right: 1vw;
}

/*Style de paragraphe*/
.Contact_paragraphe_p {
  display: flex;
  width: 40vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 3vh;
  line-height: 3.5vh;
  padding-left: 4vw;
}

.Contact_img {
  height: 60vh;
  padding-top: 4vh;
}

.scroll__container {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;

}

.scroll__button button {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  outline: none;
  padding-bottom: 1vh;
}

.scroll__button-image {
  animation: scrollAnimation 0.9s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(12px);
  }
}

.container {
  scroll-behavior: smooth;
}

/******************************Responsive******************************************/
/* Media query pour écrans de petite taille */
@media only screen and (max-width: 768px) {
  .Contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 40vh;
  }

  .Contact_titre {
    display: flex;
    flex-direction: column;
    width: 77vw;
    font-size: 3.5vh;
    color: var(--text-color);
    font-family: "Product Sans", sans-serif;
    font-weight: 700;
    padding-bottom: 7vh;
    text-align: left;
    padding-top: 12vh;
    padding-right: 5vw;

  }

  .Contact_paragraphe_p {
    display: flex;
    width: 88vw;
    flex-direction: column;
    color: var(--text-color);
    font-size: 2.2vh;
    font-family: "Poppins", sans-serif;
    padding-top: 2vh;
    text-align: left;
    padding-left: 6vw;
  }

  .Contact_img {
    display: none;
  }

  .scroll__button {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .Contact {
      display: flex;
      flex-direction: column;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .Contact_titre {
      color: var(--text-color);
    }

    .Contact_paragraphe_p {
      color: var(--text-color);
    }
  }
}