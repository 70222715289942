.Rejoigner {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  padding-bottom: 10vh;
}

.Rejoigner__block {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);

}

.Rejoigner_titre {
  display: flex;
  flex-direction: column;
  width: 35vw;
  color: var(--text-color);
  text-align: left;
  font-size: 4vh;
  font-weight: 700;
  line-height: 4vh;
  height: 6vh;
  font-family: "Product Sans", sans-serif;
  padding-bottom: 2vh;
  padding-left: 10.5vw;
  padding-top: 16vh;
}

.Rejoigner_paragraphe {
  display: flex;
  flex-direction: column;
  width: 85vh;
  color: var(--text-color);
  text-align: left;
  font-size: 3vh;
  line-height: 3.5vh;
  padding-left: 10.5vw;
  padding-bottom: 22vh;
}

.scroll__container {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__button button {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  outline: none;
  padding-bottom: 1vh;
}

.scroll__button-image {
  animation: scrollAnimation 0.9s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(12px);
  }
}

.Rejoigner {
  scroll-behavior: smooth;
}

/***********Responsive****************/
@media only screen and (max-width: 768px) {
  .Rejoigner {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
   
  }
  .Rejoigner_titre {
    display: flex;
    flex-direction: column;
    width: 77vw;
    font-size: 3.8vh;
    color: var(--text-color);
    font-family: "Product Sans", sans-serif;
    font-weight: 700;
    padding-left: 6vw;
    padding-bottom: 9vh;
    text-align: left;
    padding-top: 11vh;
  }

  .Rejoigner_paragraphe {
    display: flex;
    width: 87vw;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--text-color);
    font-size: 2.2vh;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    padding-left: 6vw;
    text-align: left;
  }
}