.Service {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: var(--background-color);
  background-image: url('../../../assets/images/service.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 12vh 0vh;
  box-sizing: border-box;
}

[data-theme='light'] .Service {
  background-image: none;
}

/*Style de conteneur qui contien quatre blocs*/
.Service__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6vh;
  padding-top: 8vh;
  gap: 5vh;
  /*Espacement entre les 4 blocs*/
}

/*Style de titre (nos services)*/
.Service_Accueil_titre {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Product Sans", sans-serif;
  font-size: 6vh;
  position: relative;
  color: var(--text-color);
}

/*Style de la ligne au dessous de titre*/
.Service_Accueil_titre:after {
  content: "";
  position: absolute;
  bottom: -1vh;
  width: 38vh;
  height: 1.3vh;
  background-color: #f801eb;
  border-radius: 4vh;
}

/*Style de cadre*/
.Service__cadre {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35vh;
  height: 42vh;
  border: 0.35vh solid var(--service-cadre-border);
  border-radius: 3.3vh;
  gap: 0.1vh;
  cursor: pointer;
  background-color: var(--service-border-color);
}

/*Style de cadre au clic */
.Service__cadre:hover {
  background-color: #350B8D;
  border-color: #350B8D;
}

/****************** premier cadre *******************/
/*Style de l'image*/
.Service__cadre_image {
  display: flex;
  width: 12vh;
}

/*Style de titre dans le cadre */
.Service__cadre_titre {
  display: flex;
  color: #FFF;
  text-align: center;
  font-size: 2vh;
  font-family: "Product Sans", sans-serif;
  line-height: 3vh;
  width: 30vh;

}

/*Style de paragraphe*/
.p {
  display: flex;
  text-align: center;
  font-size: 1.7vh;
  width: 30vh;
  padding-bottom: 2vh;
}

/*style de bloc qui contien (read more et fleche)*/
.readMore {
  display: flex;
  text-align: center;
  gap: 2vh;
}
/*couleur de lien read more*/
a:-webkit-any-link{
  color: white;
}
.read-more-text {
  font-size: 2vh;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

/************************/
/*Style de l'image*/
.Service__cadre_image2 {
  display: flex;
  width: 10vh;
  padding-bottom: 2.3vh;

}

/*Style de titre*/
.Service__cadre_titre2 {
  display: flex;
  color: #FFF;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2vh;
  font-family: "Product Sans", sans-serif;
  width: 30vh;
  padding-bottom: 2.5vh;
}

/*Style de paragraphe*/
.p2 {
  display: flex;
  text-align: center;
  font-size: 1.7vh;
  width: 30vh;
  padding-bottom: 4vh;

}

/*Style de l'image*/
.Service__cadre_image3 {
  display: flex;
  width: 11vh;
  padding-top: 0.1vh;
  padding-bottom: 1vh;

}

/*Style de titre*/
.Service__cadre_titre3 {
  display: flex;
  color: #FFF;
  text-align: center;
  font-size: 2vh;
  font-family: "Product Sans", sans-serif;
  line-height: 3vh;
  width: 25vh;
  padding-bottom: 0.1vh;
}

/*Style de paragraphe*/
.p3 {
  display: flex;
  text-align: center;
  font-size: 1.7vh;
  width: 30vh;
  padding-bottom: 2.3vh;
}

/******************************/
.Service__cadre_image4 {
  display: flex;
  width: 10vh;
  padding-bottom: 1vh;
}

/*Style de titre*/
.Service__cadre_titre4 {
  display: flex;
  color: #FFF;
  text-align: center;
  font-size: 2vh;
  font-family: "Product Sans", sans-serif;
  line-height: 2.5vh;
  width: 25vh;
  padding-bottom: 1vh;

}

/*Style de paragraphe*/
.p4 {
  display: flex;
  text-align: center;
  font-size: 1.7vh;
  width: 30vh;
  line-height: 2vh;
  padding-bottom: 1vh;

}

/*********Animation des cadres *********/
.Service__cadre:hover .Service__cadre_image,
.Service__cadre:hover .Service__cadre_image2,
.Service__cadre:hover .Service__cadre_image3,
.Service__cadre:hover .Service__cadre_image4 {
  transform: scale(1.2);
  transition: transform 0.5s;
}

.Service__cadre:hover .p,
.Service__cadre:hover .p2,
.Service__cadre:hover .p3,
.Service__cadre:hover .p4,
.Service__cadre:hover .readMore {
  transform: translateY(1.3vh);
  transition: transform 0.5s;
}

.Service__cadre:hover .Service__cadre_titre,
.Service__cadre:hover .Service__cadre_titre2,
.Service__cadre:hover .Service__cadre_titre3,
.Service__cadre:hover .Service__cadre_titre4 {
  transform: translateY(1.3vh);
  /* Move the title 5 pixels down */
  transition: transform 0.5s;
}

/* Style de bouton (Commencez un projet) */
.btn_projet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25vh;
  height: 5.5vh;
  border-radius: 4vh;
  background: var(--btn-projet-background-color);
  color: #fff;
  font-size: 1.8vh;
  font-family: "Poppins", sans-serif;
  border: 0.3vh solid #9905be;
  cursor: pointer;
  position: relative;
}

/*Style de bouton au clic*/
.btn_projet:hover {
  background: linear-gradient(to right, #f801eb, #350b8d);
  /* dégradation de gauche à droite avec les couleurs #f801eb et #350b8d */
  color: #fff;
  /* Couleur du texte de l'élément en blanc */
  background-size: 130% auto;
  /* Taille du dégradé à 130% de la largeur et à la hauteur automatique */
  animation: gradientAnimation 0.6s linear forwards;
  border-color: #f801eb, #350b8d;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

