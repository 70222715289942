.Rejoigner_Formulaire {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 16vh;
}

/********** Style de formulaire **********/
.Rejoigner_Formulaire__block form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Style des inputs (nom,prenom,num,email) */
.Rejoigner_Formulaire__inputs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Product Sans", sans-serif;
  padding-top: 2vh;
}

.Rejoigner_Formulaire__inputs-container input[type="text"],
.Rejoigner_Formulaire__inputs-container input[type="email"],
.Rejoigner_Formulaire__inputs-container input[type="tel"] {
  width: 28.5vw;
  margin: 0.8vh;
  padding: 2vh;
  font-size: 2vh;
  border-radius: 2vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
  color: var(--text-color);

}

/*Style de dropdown (sujet)*/
.subject {
  padding-right: 38vw;
}

.Rejoigner_Formulaire__inputs-container input[name="objet"],
.Rejoigner_Formulaire__inputs-container select {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24vw;
  margin: 0.8vh;
  padding: 2vh;
  font-size: 2vh;
  border-radius: 2vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
  color: var(--text-color);

}

/* Style de dropdown menu */
.Rejoigner_Formulaire__inputs-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../../assets/images/Polygon 2.png') no-repeat;
  background-position: right 2.2vh center;
  background-size: 2vh;

}

/* Style des options pour dropdown menu */
.Rejoigner_Formulaire__inputs-container select option {
  background-color: var(--background-color);
  color: var(--text-color);
}

/*Style de l'input joindre un fichier*/
.Rejoigner_Formulaire__inputs-container input[name="joindre un fichier"] {
  width: 59.8vw;
  margin: 0.8vh;
  padding: 1.7vh;
  border-radius: 2vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
}

input[type="file" i] {
  background-color: black;
  color: azure;
}

input[type="file"] {

  writing-mode: horizontal-tb !important;
  color: #958f8f;
}

/* Style de l'input (message) */
.Rejoigner_Formulaire__inputs-container textarea[name="message"] {
  width: 60.5vw;
  margin: 1.5vh;
  font-family: "Product Sans", sans-serif;
  padding-top: 2vh;
  padding-left: 1vw;
  font-size: 2vh;
  border-radius: 2vh;
  background-color: var(--background-color);
  border: 0.30vh solid var(--border-color);
  height: 21vh;
  color: var(--text-color);
}

/*couleur de placeholder selon le mode*/
.Rejoigner_Formulaire__inputs-container input::placeholder,
.Rejoigner_Formulaire__inputs-container textarea::placeholder {
  color: var(--placeholder-color);
}

/* Style des boutons */
.Rejoigner_Formulaire__container_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  padding-right: 3vw;
}

/* Style de  bouton (Réinitialiser) */
.Rejoigner_Formulaire__reset-button {
  border: none;
  background-color: transparent;
  color: var(--text-color);
  font-size: 1vw;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 21vw;
  cursor: pointer;
  padding: 2vh 3vh;
}

.Rejoigner_Formulaire__reset-button:hover {
  font-size: 1.1vw;
}

/* Style de bouton (Envoyer) */
.Rejoigner_Formulaire__buttons-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12vw;
  height: 5.3vh;
  border-radius: 4vh;
  background-color: transparent;
  font-size: 1.8vh;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 22vw;
  cursor: pointer;
  padding: 2vh 3vh;
}

/* Media Query for small screens */
@media only screen and (max-width: 768px) {

  /* Adjust the input containers to be displayed as a column */
  .Rejoigner_Formulaire__inputs-container {
    flex-direction: column;
    justify-content: center;
  }

  /* Update the width and height of the inputs and textarea */
  .Rejoigner_Formulaire__inputs-container input[type="text"],
  .Rejoigner_Formulaire__inputs-container input[type="email"],
  .Rejoigner_Formulaire__inputs-container input[type="tel"],
  .Rejoigner_Formulaire__inputs-container input[type="file"] {
    display: flex;
    width: 60vw;
    height: 4vh;
    padding: 1vh;
    font-size: 2vh;
    border-radius: 2vh;
    background-color: var(--background-color);
    border: 0.35vh solid var(--border-color);
    padding-bottom: 1.5vh;
  }

  .Rejoigner_Formulaire__inputs-container textarea[name="message"] {
    display: flex;
    width: 59vw;
    height: 15vh;
    padding: 1.5vh;
    font-size: 2vh;
    border-radius: 2vh;
    background-color: var(--background-color);
    border: 0.35vh solid var(--border-color);
  }

  .subject {
    display: flex;
    justify-content: center;
    padding-right: 0;

  }

  .Rejoigner_Formulaire__inputs-container input[name="objet"],
  .Rejoigner_Formulaire__inputs-container select {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7vh;
    width: 62.3vw;
    padding: 1vh;
  }

  /* Adjust the "Réinitialiser" button */
  .Rejoigner_Formulaire__reset-button {
    display: flex;
    height: 5vh;
    font-size: 1.8vh;
  }

  .Rejoigner_Formulaire__reset-button:hover {
    display: flex;
    font-size: 1.9vh;
  }

  /* Adjust the "Envoyer" button */
  .Rejoigner_Formulaire__buttons-container button {
    display: flex;
    width: 25vw;
    height: 5vh;
    font-size: 1.8vh;
    background: linear-gradient(to right, #f801eb, #350b8d);
    border: 0.1vh;
  }

  /* Style des boutons */
  .Rejoigner_Formulaire__container_btn {
    display: flex;
    padding-top: 2vh;
    padding-right: 46vw;

  }


}