.About {
  display: flex;
  background-color: var(--background-color);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0vh;
}

.About__container3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.About__block3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titre3 {
  display: flex;
  width: 35vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 4.3vh;
  font-weight: 700;
  line-height: 4vh;
  height: 1vh;
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif;
  padding-bottom: 7vh;
  padding-left: 2vw;
}

/* Style de paragraphe */
.paragraphe3 {
  display: flex;
  width: 36vw;
  flex-direction: column;
  color: var(--text-color);
  text-align: left;
  font-size: 2.8vh;
  line-height: 3.5vh;
  padding-left: 3vw;
}

.img {
  width: 700px;
  height: auto;
}

/* Styles du bouton scroll down */
.scroll__block {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__button button {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  padding-top: -8vh;
}

.scroll__button-image {
  animation: scrollAnimation 0.7s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(8px);
  }
}

.container {
  scroll-behavior: smooth;
}

/*******Responsive******/
@media only screen and (max-width: 768px) {
  .About {
    display: flex;
    padding-bottom: 23vh;
  }

  .titre3 {
    display: flex;
    color: var(--text-color);
    font-family: "Product Sans", sans-serif;
    width: 77vw;
    font-size: 4vh;
    text-align: left;
    padding-top: 12vh;
    padding-right: 12vw;

  }

  .paragraphe3 {
    display: flex;
    color: var(--text-color);
    width: 90vw;
    padding-top: 7vh;
    font-size: 2.4vh;
    text-align: left;
    padding-bottom: 15vh;
    padding-left: 4vw;

  }

  .img {
    display: none;
  }

  .scroll__container {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .About {
      display: flex;
      flex-direction: column;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .titre3 {
      color: var(--text-color);    }

    .paragraphe3 {
      color: var(--text-color);    }
  }
}