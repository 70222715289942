.About_service {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-bottom: 5vh;
  padding-top: 5vh;
}

.About__container, .About__containerr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.8vh;
  padding-top: 5vh;
}
.About__block,.About__blockk {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.About__block_info{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* Style of the image */
.image_AR {
  max-width: 24%;
  height: auto;
  bottom: 2vh; 
  padding-left: 48vh;
  padding-top: 3vh;
}

.image_ARlight {
  max-width: 50%;
  height: auto;
  padding-left: 35vh;
}

/* Style of title */
.titre {
  display: flex;
  width: 70vh;
  height: 6vh;
  flex-direction: column;
  justify-content: flex-start;
  color: #f801eb;
  font-size: 3.7vh;
  font-family: "Product Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 0.1vh; /* Espacement entre le titre et le paragraphe */
  padding-right: 43vh;
}
/* Style of paragraph */
.paragraphe {
    display: flex;
    width: 64vh;
    text-align: justify;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--text-color);
    font-size: 2.2vh;
    font-family: "Poppins", sans-serif;
    line-height: 24px; /*espacement entre les lignes*/
    padding-right: 49vh;

}
/* Style of button */
.btn, .btnn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
    height: 5.3vh;
    border-radius: 4vh;
    background: var(--service-btn-color);
    color: #fff;
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be; /*taille, type et couleur de bordure de bouton*/
    position: relative;
    right: 44vh;
    cursor: pointer;  
}
@keyframes gradientAnimation {
  0% {
    background-size: 250% auto;
  }
  100% {
    background-size: 100% auto;
  }
}

.btn:hover , .btnn:hover , .btn2:hover, .btn4:hover {
  background: linear-gradient(to right, #f801eb, #350b8d);
  color: #fff;
  animation: gradientAnimation 0.4s linear forwards;
  border-color: #f801eb, #350b8d; 
}

.About__container > .About__block {
  flex: 1;
}

.About__container + .About__container {
  margin-top: 2.8vh;
}
/***********************************/
.About__container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
  padding-top: 5vh;
}
.About__block2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image_AR2 {
  width: 399px; 
  height: 437px;
  height: auto;
  bottom: 2vh; 
  padding-top: 3vh;
}
.titre2 {
  display: flex;
  color: #F801EB;
  text-align: right;
  font-size: 3.7vh;
  font-weight: 700;
  line-height: 37px;
  width: 45vh;
  height: 6vh;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif; 
  padding-left: 33vh;
  margin-bottom: 3vh;
}
.paragraphe2 {
  display: flex;
  text-align: justify;
  width: 440px;
  flex-direction: column;
  color: var(--text-color);
  font-size: 2.2vh;
  line-height: 24px;/*espacement entre les lignes*/
  justify-content: flex-start;
  padding-left: 19vh;
  font-family: "Poppins", sans-serif;
}
.btn2 {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
    height: 5.3vh;
    border-radius: 4vh;
    background:var(--service-btn-color);
    color: #fff;
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be; /*taille, type et couleur de bordure de bouton*/
    position: relative;
    left: 27vh;
    cursor: pointer;   
}

@keyframes gradientAnimation {
  0% {
    background-size: 250% auto;
  }
  100% {
    background-size: 100% auto;
  }
}
.About__container2 > .About__block2 {
  flex: 1;
}

.About__container2 + .About__container2 {
  margin-top: 2.8vh;
}
/**************************************/
.titree {
  display: flex;
  width: 70vh;
  height: 6vh;
  flex-direction: column;
  justify-content: flex-start;
  color: #f801eb;
  font-size: 3.7vh;
  font-family: "Product Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 0.1vh; /* Espacement entre le titre et le paragraphe */
  padding-right: 42vh;
}
.paragraphee {
  display: flex;
  width: 61vh;
  text-align: justify;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-color);
  font-size: 2.2vh;
  font-family: "Poppins", sans-serif;
  line-height: 24px; /*espacement entre les lignes*/
  padding-right: 50vh;

}
.image_ARR {
  max-width: 30%;
  height: auto;
  bottom: 2vh; 
  padding-left: 52vh;
  padding-top: 3vh;
}
/****************************************/
.Service_About__container3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
  padding-top: 5vh;
  gap:6vh;
}
.Service_About__block3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Service_About_titre3 {
  display: flex;
  width: 400px;
  flex-direction: column;
  color: #F801EB;
  text-align: right;
  font-size: 3.7vh;
  font-weight: 700;
  line-height: 37px;
  height: 6vh;
  justify-content: flex-start;
  font-family: "Product Sans", sans-serif;
  margin-bottom: 3vh;
  padding-left: 19vh;
}
.Service_About_paragraphe3 {
  display: flex;
  width: 470px;
  flex-direction: column;
  color: var(--text-color);
  text-align: justify;
  font-size: 2.2vh;
  line-height: 24px;/*espacement entre les lignes*/
  justify-content: flex-start;
  padding-left: 10vh;
  font-family: "Poppins", sans-serif;
}
.image_AR3{
  width: 220px;
  height: 222px;
  bottom: 2vh; 
  padding-top: 3vh;
  padding-right: 10vh;
}
.About__container3 > .About__block3 {
  flex: 1;
}

.About__container3 + .About__container3 {
  margin-top: 2.8vh;
}
.btn4 {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
    height: 5.3vh;
    border-radius: 4vh;
    background:var(--service-btn-color);
    color: #fff;
    font-size: 1.8vh;
    font-family: "Poppins", sans-serif;
    border: 0.3vh solid #9905be; /*taille, type et couleur de bordure de bouton*/
    position: relative;
    left: 24vh;
    cursor: pointer;   
}
