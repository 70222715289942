.Information_Apropos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}
  .Information__container_Apropos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 12vh;
    padding-top: 8vh;
    padding-left: 3vh;
    gap: 11vh; /*Espacement entre les deux cadres*/
  }
/**********************style de premier cadre *******************/
/*Style de cadre*/  
  .Information__cadre1_Apropos {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color:var(--Information__cadre1_Apropos);
    width: 39vh;
    height: 27vh;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    border: 0.35vh solid white;
    border-radius: 3.3vh;
    transition: background-color 1s ease; /*animation au clic*/
  }
/*Style de cadre au clic de curseur */
  .Information__cadre1_Apropos:hover {
    background-color: #350B8D;
    border-color: transparent;
    cursor: pointer;
  }

/*Style de titre dans le cadre (Nos informations)*/
  .Information__cadre1_titre_Apropos {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    color: #FFF;
    text-align: center;
    font-size: 2.4vh;
    font-family: "Product Sans", sans-serif;
    padding-top: 1vh;
    position: relative;
  }

/*Style de ligne sous le titre*/
  .Information__cadre1_titre_Apropos:after {
    content: "";
    position: absolute;
    width: 20vh;
    height: 0.35vh;
    background-color: #f801eb;
    border-radius: 4vh;
    bottom: -0.1vh; /*L'emplacement de l'alignement par rapport au titre*/
  }

/*Style de contenu de cadre */
  .Information__cadre1_Information_Apropos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 40vh;
    color: #FFF;
    font-size: 2.1vh;
    font-family: "Poppins", sans-serif;
    line-height: 2.7vh; /*espacement entre les lignes de contenu*/
    
  }
/*Style de chaque span*/
  .Information__cadre1_Information_Apropos > span {
    padding-bottom: 1.2vh; 
  }

/***************** Style de deuxième cadre***************/
  /*Style de cadre*/  
  .Information__cadre2_Apropos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color:var(--Information__cadre2_Apropos);
    width: 44.9vh;
    height: 27vh;
    border: 0.35vh solid white;
    border-radius: 3.3vh;
    transition: background-color 1s ease-out;
  }
  /*Style de cadre au clic */
  .Information__cadre2_Apropos:hover {
    background-color: #350B8D;
    border-color: transparent;
    cursor: pointer;
  }
  
  /*Style de titre dans le cadre */
  .Information__cadre_titre2_Apropos {
    display: flex; 
    color: #FFF;
    text-align: center;
    font-size: 2.5vh;
    font-family: "Product Sans", sans-serif;
    line-height: 3.5vh;
    width:23vh;
  }

/*Style de l'image de deuxième cadre */
  .Information__cadre_image {
    width: 8vh;
    border-radius: 50%;
    padding-top: 0.1vh;
  }
  
  
  
  
  