.Apropos_Definition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12vh;
    background-color: var(--background-color);

  }
  /*Style de conteneur*/
.Apropos_Definition__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.8vh;
    padding-top: 6vh;
    gap:10vh; /*Espacement entre image et paragraphe*/
  }
  /*Style de bloc*/
.Apropos_Definition__block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } 
  /* Style de l'image */
.Apropos_Image {
    width: auto;
    height: auto;
    bottom: 2vh; 
    padding-right: 1vh;
  }
  /* Style de paragraphe */
.Apropos_Definition_paragraphe {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 82vh;
      color: var(--text-color);
      font-size: 2.4vh;
      font-family: "Poppins", sans-serif;
      line-height: 3.2vh; /*espacement entre les lignes*/
      font-weight: normal;
      text-align: justify;
  }
  /*Style de span (les parties de paragraphe)*/
.Apropos_Definition_paragraphe > span {
    padding-bottom: 4vh; /* espacement entre les parties du paragraphe */
  }
  /* Align the blocks horizontally */
.Apropos_Definition__container > .Apropos_Definition__block {
    flex: 1;
  }
  /* Add margin between containers */
.Apropos_Definition__container + .Apropos_Definition__container {
    padding-top: 2.8vh;
  }