:root {
  --background-color: #fff;
  --text-color: #121416d8;
}

[data-theme='light'] {
  --background-color: #fff;
  --link-color:  #350B8D;
  --text-color: #350B8D;
  --border-color: #350B8D;
  --border-bloc-color: #350B8D;
  --placeholder-color: #350B8D;
  --background: #fff;
  --bloc-color:rgb(209, 123, 123);
  --scroll-color:#350B8D;
  --scroll:#350B8D;
  --navbar-link: #350B8D;
  --background-footer:#350B8D;
  --border-footer: #350B8D; 
  --background-color-footer: #fff; 
  --background-map:#fff;
  --Accueil_About_titre: linear-gradient(to left, #F801EB 70%,#350B8D 30%);
  --Accueil_About_titre_responsive: linear-gradient(to bottom, #F801EB 50%,#350B8D 30%);
  --white-text-color:#350B8D;
  --service-cadre-border:#350B8D;
  --service-border-color:#350B8D;
  --btn-projet-background-color: linear-gradient(to right, #f801eb, #350b8d);
  --Partenaire-titre-span: linear-gradient(to left,  #F801EB 70%, #350B8D 18%);
  --Partenaire-container-color:  rgba(248, 1, 235, 0.05); 
  --Information__cadre1_Apropos: #350B8D;
  --Information__cadre2_Apropos: #350B8D;
  --Formulaire__buttons-container: linear-gradient(to right, #f801eb, #350b8d);
  --cadre:#350B8D;
  --service-btn-color:linear-gradient(to right, #f801eb, #350b8d);
  --dropdown: linear-gradient(180deg, #5027a9 0%, #543d85 );
  --btn_responsive:linear-gradient(to right, #f801eb, #350b8d);
  --toggleMobileMenu: #000;
  --root:#fff;
  --link:#fff;

}


[data-theme='dark'] {
  --background-color: #000;
  --text-color: #fff;
  --link-color: #fff;
  --border-color: #fff;
  --border-bloc-color: #000;
  --placeholder-color: #fff;
  --background: linear-gradient(180deg, #000 0%, #560252 );
  --bloc-color:#fff;
  --btn-color:#fff;
  --scroll-color:#fff;
  --scroll:#00a8ff;
  --navbar-link: #fff;
  --background-footer: transparent;
  --border-footer: transparent;
  --background-color-footer: linear-gradient(180deg, #000 0%, #560252);
  --background-map:#000;
  --Accueil_About_titre:linear-gradient(to left, #F801EB 70%, #FFF 32%);
  --Accueil_About_titre_responsive :linear-gradient(to bottom, #Fff 60%, #F801EB 25%);
  --white-text-color:#fff;
  --service-cadre-border:#fff;
  --service-border-color: transparent;
  --btn-projet-background-color: transparent;
  --Partenaire-titre-span: linear-gradient(to left, #F801EB 70%, #FFF 18%);
  --Partenaire-container-color: #fff; 
  --Information__cadre1_Apropos: transparent;
  --Information__cadre2_Apropos: transparent;
  --Formulaire__buttons-container:transparent;
  --cadre:transparent;
  --service-btn-color:transparent;
  --dropdown: linear-gradient(180deg, #530a47 0%, #b079ad );
  --btn_responsive:linear-gradient(to right, #f801eb, #350b8d);
  --toggleMobileMenu: #fff;
  --root:#000;
  --link:#000;

}