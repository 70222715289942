.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 5vh 9vw;

}

/*Style de liens quand le navbar est d'arriere plan (transparent)*/
.navbar.transparent {
  background-color: transparent;
}

.navbar__button {
  background-color: transparent;
  border: 0;
}

/* Style des trois éléments de la navbar */
.navbar ul {
  display: flex;
  justify-content: space-between;
  gap: 4vw;
}

/* Style des liens */
.navbar li {
  display: flex;
  font-weight: normal;
  font-family: "Product Sans", sans-serif;
}

.navbar li a {
  color: var(--navbar-link);
  text-decoration: none;
}

/* Style du lien actif */
.navbar li a.active {
  color: #f801eb;
}

/* Définir l'espacement entre les liens */
.navbar ul.navbar__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar ul.navbar__links li {
  flex-grow: 1;
  text-align: center;
}

.navbar__button {
  cursor: pointer;
}

/***********Responsive**********/
@media screen and (max-width: 768px) {

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5vw;
    padding-top: 3vh;
    gap: 21vw;
  }

  .bloc {
    display: flex;
    align-items: center;
    gap: 6vw;
  }

  .navbar ul.navbar__links li {
    display: none;
  }

  .navbar ul.navbar__button {
    display: none;
  }

  .logo {
    display: flex;
    width: 25vw;
  }

  .navbar__button {
    display: flex;

  }

  .white-icon {
    display: flex;
    color: var(--toggleMobileMenu);
    flex-direction: column;
    font-size: 4vw;
  }

  /*Style de dropdown*/
  .mobile-menu-icon {
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    display: relative;
    flex-direction: column;
    position: absolute;
    right: 0;
    display: none;
    background: var(--toggleMobileMenu);
    border-radius: 1vh;
    padding: 1vh;
    width: 38vw;
    z-index: 1;
  }

  .mobile-menu-icon.active .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .dropdown-menu li a {
    display: flex;
    color: var(--link);
    transition: color 0.3s;
  }

  .dropdown-menu li a.active {
    font-weight: bold;
  }

  .navbar__button-image_mode {
    width: 7vw;
  }

  @media screen and (max-width: 600px) {
    .navbar__button-image_mode {
      width: 10vw;
    }

    .white-icon {
      font-size: 5vw;
    }

    .navbar {
      gap: 15vw;
    }
  }

}