.Accueil_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 38vh;
  padding-top: 8vh;
  position: relative;
}

.Accueil_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*Style de titre*/
.Accueil_container h1 {
  display: flex;
  width: 36vw;
  padding-bottom: 1vh;
  color: var(--text-color);
  text-align: center;
  font-size: 2vw;
  font-family: 'Product Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 4.2vh;
}

/*Style de paragraphe*/
.Accueil_container p {
  width: 35vw;
  padding-bottom: 1vh;
  color: var(--text-color);
  text-align: center;
  font-size: 3vh;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 3.5vh;
}

/*Style de bouton (explorer nos services)*/
.Accueil_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.5vw;
  height: 5.5vh;
  border-radius: 4vh;
  background-color: transparent;
  background: var(--service-btn-color);
  color: #fff;
  font-size: 1.8vh;
  font-family: 'Poppins', sans-serif;
  border: 0.3vh solid #9905be;
  cursor: pointer;
  position: relative;
}

@keyframes gradientAnimation {
  0% {
    background-position: 200% 0%;
  }

  100% {
    background-position: 100% 50%;
  }
}

/*Style de bouton au clic*/
.Accueil_btn:hover {
  background: linear-gradient(to right, #f801eb, #350b8d);
  color: #fff;
  background-size: 130% auto;
  animation: gradientAnimation 0.6s linear forwards;
  border-color: #f801eb, #350b8d;
}

/*Style de scroll down*/
.ScrollDown_button {
  position: absolute;
  bottom: 10vh;
  right: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--scroll-color);
  font-size: 2vh;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  animation: scrollAnimation 0.7s infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(12px);
  }
}

/*Style de bouton de scroll*/
.Arrow {
  width: 1.5vw;
  height: 2vh;
  border-bottom: 0.4vh solid var(--scroll);
  border-right: 0.4vh solid var(--scroll);
  transform: rotate(45deg);
  padding-top: 1vh;
  transition: transform 0.3s ease;
}

/***********Responsive******/
@media only screen and (max-width: 768px) {

  .Accueil_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40vh;
    padding-top: 15vh;
    position: relative;
  }

  .Accueil_container h1 {
    display: flex;
    font-size: 4vw;
    width: 75vw;
    line-height: 5vw;
  }

  .Accueil_container p {
    display: flex;
    font-size: 3.8vw;
    width: 90vw;
    line-height: 5.5vw;
  }

  .Accueil_btn {
    display: flex;
    align-items: center;
    width: 40vw;
    height: 7vw;
    border-radius: 5vh;
    font-size: 3vw;
  }

  .Accueil_btn:hover {
    background: linear-gradient(to right, #f801eb, #350b8d);
    color: #fff;
    background-size: 130% auto;
    animation: gradientAnimation 0.6s linear forwards;
    border-color: #f801eb, #350b8d;
  }

  .ScrollDown_button {
    display: none;
  }

  .Arrow {
    display: none;
  }

}