.footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: var(--background-color-footer);
  padding-left: 26vh;
  padding-bottom: 5vh;
}

.foo_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--border-footer);
  border-radius: 10px;
  background-color: var(--background-footer);
  margin-top: 10vh;
  gap: 22vh;
  margin-right: 27vh;
  padding-left: 6vh;
  padding-top: 2vh;
}

.footer__logo {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__logo .logo {
  width: 15vh;
  height: auto;
  padding-top: 2vh;
}

.footer__logo p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-size: 1.8vh;
  font-family: "Product Sans", sans-serif;
  font-weight: 550;
  width: 37vh;
}

.footer__links ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__links li {
  text-align: center;
  font-family: "Product Sans", sans-serif;
  font-size: 2.3vh;
  font-weight: normal;
  list-style-type: none;
  padding-bottom: 1vh;
  width: 20vh;
}

.footer__links li a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.footer__links li a.active {
  color: #F801EB;
}

.footer__images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__images .image1 {
  width: auto;
  height: auto;
  padding-bottom: 1.4vh;
}

.footer__images .image2 {
  width: 15vh;
  height: auto;
}

.footer__button button {
  border: none;
  background: none;
  cursor: pointer;
  width: 25px;
  height: 26px;
  padding-right: 23vh;
}

body.scroll-to-top {
  scroll-behavior: smooth;
}

.scroll-to-top-animation {
  animation: scroll-to-top-animation 0.5s ease-out;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 300px;
  background: var(--dropdown);
  border-radius: 1vh;
  font-size: 2vh;
  z-index: 1;
  left: 100%;
}

.dropdown:hover {
  transform: scale(1.1);
  transition: transform 0.5s;
}

.dropdown:hover .dropdown-content {
  display: block;
  top: 0%;
  left: auto;
  right: 125%;
  transform: translateX(160%);
}

.dropdown-content a {
  text-align: justify;
  padding: 6px 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  text-decoration: underline;
}

/* responsive */
@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer__block {
    flex-basis: 100%;
    margin-bottom: 2vh;
    align-items: center;
  }

  .footer__logo {
    display: none;
  }

  .footer .footer__images {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer .footer__links {
    display: none;
  }

  .footer .footer__button {
    display: none;
  }

  .foo_total {
    display: flex;
    justify-content:center;
    align-items: center;
    border: 2px solid var(--border-footer);
    border-radius: 10px;
    background-color: var(--background-footer);
    margin-top: 10vh;
    width: 60vw;
    padding-right: 7.2vw;
    padding-bottom: 2vh;
  }
}