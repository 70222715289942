.container_map {
  display: flex;
  width: 100%;
  height: 60vh; 
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-color: var(--background-map);
}
.Map__block {
  display: flex;
  width: 100%;
  height: 100%; 
}
.Map__block iframe {
  display: flex;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .container_map {
    display: none;
  }
}
