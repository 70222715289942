.Accueil_About {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
  padding-top: 15vh;
  background-color: var(--background-color);
}

.Accueil_About__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15vh;
  gap: 3vw;
}

.Accueil_About__container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vh;
  gap: 3vw;
  padding-left: 12vw;
}

.Accueil_About__block {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 32vh;
}

[data-theme='light'] .contour {
  display: flex;
  background-color: var(--border-color);
  border-radius: 2vh;
}

/* Style de titre */
.Accueil_About_titre {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: right;
  font-size: 45px;
  font-weight: 700;
  font-family: "Product Sans", sans-serif;
  margin-bottom: 2.2vh;
  /* Espacement entre le titre et le paragraphe */
  margin-left: 2vw;
  padding-left: 1vw;
  background: var(--Accueil_About_titre);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Style de paragraphe */
.Accueil_About_paragraphe {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 28vw;
  font-size: 16px;
  color: var(--text-color);
  line-height: 3.2vh;
  /*espacement entre les lignes*/
}

/* Style de l'image */
.Frame2 {
  display: flex;
  width: 21vw;
  height: auto;
}

[data-theme='light'] .contour {
  background-color: var(--border-color);
  width: 44vh;
  border-radius: 4vh;
}

/******************************/
.Accueil_About__block2 {
  display: flex;
  flex-direction: column;
  height: 48vh;
}


[data-theme='light'] .contour2 {
  background-color: var(--border-color);
  border-radius: 4vh;
  margin-left: 7vw;
  padding: 8vh 4vw;
}

/* Style de titre */
.Accueil_About_titre2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  width: 45vw;
  height: 6vh;
  font-family: "Product Sans", sans-serif;
  padding-bottom: 5vh;
  /* Espacement entre le titre et le paragraphe */
  padding-left: 2vw;

}

.white-text {
  color: var(--white-text-color);
}

.purple-text {
  color: #F801EB;
}

/* Style de paragraphe */
.Accueil_About_paragraphe2 {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 65vh;
  font-size: 2.3vh;
  color: var(--text-color);
  line-height: 3.2vh;
  /*espacement entre les lignes*/
  padding-left: 2vw;

}

.Frame {
  display: flex;
}

/**************************Responsive****************************/
@media only screen and (max-width: 768px) {
  .Accueil_About {
    display: flex;
    flex-direction: column;
    padding-bottom: 4vh;
    padding-top: 7vh;
  }

  .Accueil_About__container {
    gap: 4vw;
  }

  .Accueil_About_titre {
    display: flex;
    font-size: 6vw;
    text-align: left;
    margin-left: 0vw;
    padding-left: 0vw;
    padding-bottom: 0vh;
    background: var(--Accueil_About_titre_responsive);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .Accueil_About_paragraphe {
    display: flex;
    width: 49vw;
    text-align: justify;
    font-size: 2.8vw;
    line-height: 5.2vw;
  }

  .Frame2 {
    display: flex;
    width: 40vw;
    height: auto;
    padding-top: 30vw;
  }

  .Accueil_About__block {
    padding-left: 8vw;
  }

  [data-theme='light'] .contour {
    display: flex;
    background-color: var(--border-color);
    border-radius: 4vh;
    width: 35vw;
    margin-right: 4vw;
    margin-top: 30vw;
  }

  [data-theme='light'] .Frame2 {
    display: flex;
    width: 35vw;
    padding-top: 1vh;

  }

  /*************************************************/
  .Accueil_About__container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20vw;
    gap: -2vw;
  }

  .Accueil_About_titre2 {
    display: flex;
    width: 60vw;
    font-size: 6vw;
    text-align: right;
    height: 2vh;
    padding-right: 13vw;
    padding-bottom: 9vw;
  }

  .Accueil_About_paragraphe2 {
    display: flex;
    width: 58vw;
    text-align: justify;
    font-size: 2.8vw;
    line-height: 5.2vw;
    padding-left: 3vw;
  }

  .Frame {
    display: flex;
    width: 20vw;
    justify-content: center;
  }

  .Accueil_About__block2 {
    display: flex;
    padding-right: 10vw;

  }

  [data-theme='light'] .contour2 {
    display: flex;
    background-color: var(--border-color);
    border-radius: 4vh;
    justify-content: center;
    padding: 7vh 5vw;
  }

  .bloc_contour {
    display: flex;
    padding-top: 50vh;
  }

  @media screen and (max-width: 600px) {
    .Accueil_About_titre {
      font-size: 7vw;
    }

    .Accueil_About_paragraphe {
      font-size: 4vw;
    }

    .Accueil_About_titre2 {
      font-size: 7vw;
      padding-right: 18vw;
    }

    .Accueil_About_paragraphe2 {
      font-size: 4vw;
    }

    .bloc_contour {
      display: flex;
      padding-top: 10vh;
    }

    [data-theme='light'] .contour2 {
      padding: 4.5vh 4.5vw;
    }
  }
}