[data-theme="dark"] .header_container{
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.745), rgba(0, 0, 0, 0.7)), 
    url('../../../assets/images/accueil.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

[data-theme="light"] .header_container {
    /* Ajoutez le nouveau gradient de fond pour le mode clair */
    background-image: linear-gradient(to top, rgba(253, 249, 249, 0.7), rgba(218, 194, 194, 0.3)), 
    url('../../../assets/images/accueil.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

/***********Responsive****/
@media only screen and (max-width: 768px) {
.header_container{
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center ;
    background-image: none !important;
    }
 
}